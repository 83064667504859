import React from 'react';

interface Props {
  visible: boolean;
}

const Overlay: React.FC<Props> = ({
  visible,
}: Props) => (
  <div className={visible ? 'overlay visible' : 'overlay'}>
    <div className="top">
      <em>CVD</em>
      <p>Circulus Vitae Digitalis</p>
    </div>
    <div className="middle">
      <p>Your next digital agency</p>
    </div>
    <div className="bottom">
      <p>646 334 0819</p>
      <p><a href="mailto:info@cvd.agency">info@cvd.agency</a></p>
    </div>
  </div>
);
export default Overlay;
