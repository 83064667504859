import { Helmet } from 'react-helmet';
import Landing from './page/Landing';

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CVD | Circulus Vitae Digitalis | Digital Agency</title>
        <link rel="canonical" href="http://cvd.agency/" />
        <meta name="description" content="CVD (Circulus Vitae Digitalis) is a digital agency" />
      </Helmet>
      <Landing />
    </div>
  );
}

export default App;
