import { useState } from 'react';
import Overlay from './Overlay';
import Particles from './Particles';

function Landing() {
  const [overlayVisible, setOverlayVisible] = useState(false);

  const handleInfoClick = () => {
    setOverlayVisible(!overlayVisible);
  };

  return (
    <div className="page landing">
      <div className="button-wrapper">
        <button
          className={overlayVisible ? 'more-info visible' : 'more-info'}
          type="button"
          onClick={handleInfoClick}
        >
          info
        </button>
      </div>
      <div className="ring">
        <div className="text">
          <svg viewBox="0 0 250 250">
            <path
              id="curve"
              fill="transparent"
              d="M 131.78166,66.145836 A 65.717812,65.799782 0 0 1 66.06385,131.94562 65.717812,65.799782 0 0 1 0.34603882,66.145836 65.717812,65.799782 0 0 1 66.06385,0.34605408 65.717812,65.799782 0 0 1 131.78166,66.145836 Z"
            />
            <text>
              <textPath xlinkHref="#curve">
                Circulus Vitae Digitalis
                &nbsp;
                Circulus Vitae Digitalis
              </textPath>
            </text>
          </svg>
        </div>
        <span className="gradient" />
        <span className="reverse" />
      </div>
      <div className="logo-box">
        <div className="logo">
          <span className="text">
            <em>CVD</em>
          </span>
          <span className="gradient" />
          <span className="spotlight" />
        </div>
      </div>
      <Particles />
      <Overlay visible={overlayVisible} />
    </div>
  );
}

export default Landing;
