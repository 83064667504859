import React from 'react';

const Particles: React.FC = () => (
  <div className="particles">

    <div className="particle" />
    <div className="particle" />
    <div className="particle" />
    <div className="particle" />
    <div className="particle" />

    <div className="particle" />
    <div className="particle" />
    <div className="particle" />
    <div className="particle" />
    <div className="particle" />

    <div className="particle" />
    <div className="particle" />
    <div className="particle" />
    <div className="particle" />
    <div className="particle" />

    <div className="particle" />
    <div className="particle" />
    <div className="particle" />
    <div className="particle" />
    <div className="particle" />

    <div className="particle" />
    <div className="particle" />
    <div className="particle" />
    <div className="particle" />
    <div className="particle" />
  </div>
);

export default Particles;
